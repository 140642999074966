import { Controller } from '@hotwired/stimulus';
import Steven from '../services/steven';

export default class extends Controller {
  elementClicked(e) {
    const { event } = e.currentTarget.dataset;

    if (event) {
      try {
        const { element_id, action, element_content } = JSON.parse(event);
        Steven.elementClicked({
          id: element_id,
          content: element_content || e.currentTarget.innerText,
          type: this.element.tagName,
          action,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  trustyContactAction(e) {
    const { event } = e.currentTarget.dataset;

    if (event) {
      try {
        const {
          id,
          content,
          type,
          action,
          trusty_company_id_contacted,
          is_cocam_company_contacted,
          cocam_company_id_contacted,
        } = JSON.parse(event);
        Steven.trustyContactAction({
          id,
          content: content || e.currentTarget.innerText,
          type,
          action,
          trusty_company_id_contacted,
          is_cocam_company_contacted,
          cocam_company_id_contacted,
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log('trustyContactAction no event', this.element.dataset);
    }
  }
}
