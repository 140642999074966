import { Controller } from '@hotwired/stimulus';

// if working with multiple targets, give the element which calls 'show' an id that matches the hideable target's id
// if working with a single hideable target, there is no need for matching ids

export default class extends Controller {
  static get targets() {
    return ['hideable', 'showable'];
  }

  show({ target }) {
    this.hideableTargets.forEach((hideableTarget) => {
      if (target.dataset.visibilityId === hideableTarget.dataset.visibilityId) {
        hideableTarget.hidden = false;
        hideableTarget.classList.remove('hidden');
      } else {
        hideableTarget.hidden = true;
        hideableTarget.classList.add('hidden');
      }
    });

    // inverse of above logic so that things can be hidden on click rather than shown
    this.showableTargets.forEach((showableTargetId) => {
      if (
        target.dataset.visibilityId === showableTargetId.dataset.visibilityId
      ) {
        showableTargetId.hidden = true;
        showableTargetId.classList.add('hidden');
      } else {
        showableTargetId.hidden = false;
        showableTargetId.classList.remove('hidden');
      }
    });
  }
}
