import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static get targets() {
    return ['container', 'inner'];
  }

  innerTargetConnected() {
    useClickOutside(this, { element: this.innerTarget });
  }

  clickOutside(event) {
    if (this.shouldCloseOnOutsideClick(event)) {
      event.preventDefault();
      this.close();
    }
  }

  open() {
    this.containerTarget.hidden = false;
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.containerTarget.hidden = true;
    document.body.style.overflow = 'auto';
  }

  shouldCloseOnOutsideClick(event) {
    return event.target.contains(this.innerTarget);
  }
}
