import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

const app = Application.start();

export const controllers = import.meta.glob('./**/*_controller.js', {
  eager: true,
});
registerControllers(app, controllers);

const vc_controllers = import.meta.glob(
  '../../frontend/components/**/*_controller.js',
  { eager: true },
);

for (let path in vc_controllers) {
  let module = vc_controllers[path];
  let name = path
    .match(/frontend\/components\/(.+)\/(.+)_controller\.js$/)[1]
    .replaceAll('/', '-')
    .replaceAll('_', '-');
  app.register(name, module.default);
}
