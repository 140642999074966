import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static get targets() {
    return ['container'];
  }

  connect() {
    useClickOutside(this);
  }

  clickOutside() {
    this.close();
  }

  toggle() {
    if (this.containerTarget.hidden) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.containerTarget.hidden = false;
  }

  close() {
    this.containerTarget.hidden = true;
  }
}
