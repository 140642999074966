import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static get fetching() {
    return false;
  }

  static get values() {
    return {
      url: String,
    };
  }

  initialize() {
    useIntersection(this, { rootMargin: '500px' });
  }

  appear() {
    if (!this.fetching) {
      this.fetchNextPage();
    }
  }

  async fetchNextPage() {
    this.fetching = true;
    await get(this.urlValue, {
      responseKind: 'turbo-stream',
    });
    this.fetching = false;
  }
}
