import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['input'];
  }

  async copy() {
    try {
      await navigator.clipboard.writeText(this.inputTarget.value);
      this.dispatch('copy');
    } catch (error) {
      console.error(error.message);
    }
  }
}
