import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['buttonText', 'errorMessage', 'submitButton', 'spinner'];
  }

  static get values() {
    return {
      returnUrl: String,
      stripePublicKey: String,
      paymentRequestAmount: String,
      paymentRequestId: String,
      stripeConnectAccountIdValue: String,
    };
  }

  connect() {
    // Stripe is defined globally with the stripe.js sdk downloaded via script tag in checkout.html.erb
    // eslint-disable-next-line no-undef
    this.stripe = Stripe(this.stripePublicKeyValue);
    this.emailAddress = '';

    this.initializeStripeElements();
    this.initializeStripeLink();
  }

  clearErrorMessage() {
    this.errorMessageTarget.textContent = '';
  }

  showErrorMessage(error) {
    // Stripe handles validation errors out of the box; we want to avoid rendering them twice
    const shouldRenderError = error.type !== 'validation_error';
    if (shouldRenderError) {
      this.errorMessageTarget.textContent = error.message;
    }
    this.buttonTextTarget.disabled = false;
  }

  initializeStripeElements() {
    const options = {
      mode: 'payment',
      amount: parseInt(this.paymentRequestAmountValue, 10),
      currency: 'usd',
      onBehalfOf: this.stripeConnectAccountIdValue,
      paymentMethodCreation: 'manual',
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      },
    };

    // Set up Stripe.js and Elements to use in checkout form
    this.elements = this.stripe.elements(options);

    // Create and mount the Payment Element
    const paymentElement = this.elements.create('payment', {
      fields: {
        billingDetails: {
          email: 'never', // we capture email via link. This eliminates duplicate email fields.
        },
      },
    });
    paymentElement.mount('#payment-element');
  }

  initializeStripeLink() {
    const linkAuthenticationElement =
      this.elements.create('linkAuthentication');
    linkAuthenticationElement.mount('#link-authentication-element');

    linkAuthenticationElement.on('change', (event) => {
      this.emailAddress = event.value.email;
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.clearErrorMessage();

    // Prevent multiple submissions
    if (this.submitButtonTarget.disabled) {
      return;
    }

    this.setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await this.elements.submit();
    if (submitError) {
      this.showErrorMessage(submitError);
      this.setLoading(false);
      return;
    }
    // Create the PaymentMethod
    const { error: paymentMethodError, paymentMethod } =
      await this.stripe.createPaymentMethod({
        elements: this.elements,
        params: {
          billing_details: {
            email: this.emailAddress,
          },
        },
      });

    if (paymentMethodError) {
      this.showErrorMessage(paymentMethodError);
      this.setLoading(false);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const res = await fetch('/payment_intents', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        payment_request_id: this.paymentRequestIdValue,
        payment_request_amount: this.paymentRequestAmountValue,
        stripe_payment_method_id: paymentMethod.id,
        receipt_email: this.emailAddress,
      }),
    });

    const { client_secret: clientSecret } = await res.json();
    const isProcessing = paymentMethod.type === 'us_bank_account';

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error: confirmPaymentError } = await this.stripe.confirmPayment({
      elements: this.elements,
      clientSecret,
      confirmParams: {
        return_url: `${this.returnUrlValue}?is_processing=${isProcessing}`,
        receipt_email: this.emailAddress,
      },
    });

    if (confirmPaymentError) {
      this.showErrorMessage(confirmPaymentError);
    }

    this.setLoading(false);
  }

  setLoading(isLoading) {
    if (isLoading) {
      this.submitButtonTarget.disabled = true;
      this.spinnerTarget.classList.remove('hidden');
      this.buttonTextTarget.classList.add('hidden');
    } else {
      this.submitButtonTarget.disabled = false;
      this.spinnerTarget.classList.add('hidden');
      this.buttonTextTarget.classList.remove('hidden');
    }
  }
}
