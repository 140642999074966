import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';
const parser = new DOMParser();

// marietta, ga
const DEFAULT_CENTER = {
  lat: 33.9484548,
  lng: -84.5835713,
};

// this is map-pin.svg. how do we load that from a stimulus controller?
const pinSvgString = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="!z-50 h-9 w-9 relative">
  <path pointer-events="all" fill-rule="evenodd" d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" clip-rule="evenodd" />
</svg>
`;

// wonder if there is a way we could use view components here?
const buildInfoWindow = ({ name, location, href, logo }) => {
  return `
    <a href="${href}" target="_blank" data-turbo-frame="_top">
      <div class="font-rubik bg-primary overflow-hidden transition-all hover:underline flex flex-row items-center gap-3 p-4 pr-5">
        <div class="flex items-center w-12 h-12 shrink-0 rounded-full border border-primary ring-zinc-200 bg-primary overflow-hidden">
          <img class="w-full" src="${logo}" />
        </div>
        <div class="text-primary">
          <h3 class="font-medium text-lg leading-tight">${name}</h3>
          <p class="font-rubik font-normal text-sm">${location}</p>
        </div>
      </div>
    </a>
  `;
};

export default class extends Controller {
  static targets = ['map', 'company'];
  static values = {
    apiKey: String,
    city: String,
    mapId: String,
  };

  async connect() {
    this.initLoader();
    await this.buildMap();
    await this.addMarkers();
  }

  initLoader() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: 'weekly',
    });
  }

  async buildMap() {
    const { Map, InfoWindow } = await this.loader.importLibrary('maps');
    const { ControlPosition } = await this.loader.importLibrary('core');
    this.infoWindow = new InfoWindow();

    let center = DEFAULT_CENTER;
    let geometry;
    const result = await this.geocodeAddress(this.cityValue);
    if (result.results.length) {
      geometry = result.results[0].geometry;
      center = geometry.location;
    }

    this.map = new Map(this.mapTarget, {
      center,
      zoom: 12,
      maxZoom: 15,
      mapId: this.mapIdValue,
      disableDefaultUI: true,
      clickableIcons: false,
      zoomControl: true,
      gestureHandling: 'greedy',
      zoomControlOptions: {
        position: ControlPosition.RIGHT_TOP,
      },
    });

    // close info window if click anywhere outside of it
    this.map.addListener('click', () => {
      this.infoWindow.close();
    });

    // changes map zoom so the entire city fits on the visible map
    if (geometry) {
      this.map.fitBounds(geometry.bounds);
    }
  }

  async addMarkers() {
    const { AdvancedMarkerElement } = await this.loader.importLibrary('marker');

    this.companyTargets.map(async (company) => {
      // If we had the lat/lng stored in the db (get when address is changed?) this would be
      // faster and cheaper
      const {
        name,
        verified,
        location: companyLocation,
        href,
        logo,
        latitude,
        longitude,
      } = company.dataset;

      const isVerified = verified === 'true';

      if (!longitude || !latitude) return;

      this.addMarker({
        infoWindowContent: buildInfoWindow({
          name,
          location: companyLocation,
          href,
          logo,
        }),
        AdvancedMarkerElement,
        isVerified,
        latitude,
        longitude,
      });
    });
  }

  addMarker({
    AdvancedMarkerElement,
    isVerified,
    latitude,
    longitude,
    infoWindowContent,
  }) {
    const pinSvg = parser.parseFromString(
      pinSvgString,
      'image/svg+xml',
    ).documentElement;

    pinSvg.style.fill = isVerified ? '#8b5cf6' : '#27272A';

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
      title: name,
      content: pinSvg,
      gmpClickable: true,
    });

    const openInfoWindow = () => {
      this.infoWindow.close();
      this.infoWindow.setContent(infoWindowContent);
      this.infoWindow.open(marker.map, marker);
    };

    marker.content.addEventListener('mouseenter', openInfoWindow);
    marker.addListener('click', openInfoWindow);
  }

  async geocodeAddress(address) {
    const { Geocoder } = await this.loader.importLibrary('geocoding');
    const geocoder = new Geocoder();
    const result = await geocoder.geocode({ address: address });

    return result;
  }
}
