import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['button'];
  }

  connect() {
    this.lastScrollPosition = window.scrollY;
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    if (window.innerWidth > 768) {
      // Do nothing if viewport width is greater than 768 pixels
      return;
    }

    const currentScrollPosition = window.scrollY;
    const threshold = 500;

    if (currentScrollPosition > this.lastScrollPosition) {
      // Scrolling down
      this.buttonTarget.classList.add('opacity-0', 'bottom-0');
      this.buttonTarget.classList.remove('bottom-10', 'opacity-1');
    } else if (currentScrollPosition < this.lastScrollPosition) {
      // Scrolling up
      if (window.scrollY > threshold) {
        this.buttonTarget.classList.add('bottom-10', 'opacity-1');
        this.buttonTarget.classList.remove('opacity-0', 'bottom-0');
      } else {
        this.buttonTarget.classList.add('opacity-0', 'bottom-0');
        this.buttonTarget.classList.remove('bottom-10', 'opacity-1');
      }
    }

    this.lastScrollPosition = currentScrollPosition;
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
