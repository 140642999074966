import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['fab'];
  }

  connect() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 325) {
        this.fabTarget.classList.add('bottom-10', 'opacity-1');
        this.fabTarget.classList.remove('bottom-0', 'opacity-0');
      } else {
        this.fabTarget.classList.add('bottom-0', 'opacity-0');
        this.fabTarget.classList.remove('bottom-10', 'opacity-1');
      }
    });
  }
}
