import { Controller } from '@hotwired/stimulus';

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

export default class extends Controller {
  static get targets() {
    return ['container', 'item'];
  }

  currentIndex(element) {
    return this.itemTargets.findIndex(
      (target) => target.dataset.id === element.getAttribute('data-id'),
    );
  }

  destroy() {
    if (this.lightbox) {
      this.lightbox.destroy();
    }
  }

  disconnect() {
    this.destroy();
  }

  licenseKey() {
    return document.querySelector('meta[name=lightbox_key]').content;
  }

  lightboxItems() {
    return this.itemTargets.map(({ dataset }) => {
      return {
        src: dataset.largeImageUrl,
        thumb: dataset.thumbnailImageUrl,
      };
    });
  }

  open({ target }) {
    this.destroy();
    this.lightbox = lightGallery(this.containerTarget, {
      download: false,
      dynamic: true,
      dynamicEl: this.lightboxItems(),
      licenseKey: this.licenseKey(),
      mobileSettings: {
        showCloseIcon: true,
      },
      plugins: [lgThumbnail],
    });

    const index = this.currentIndex(target);
    this.lightbox.openGallery(index);
  }
}
