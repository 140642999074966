import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['alert'];
  }

  static get values() {
    return {
      duration: {
        type: Number,
        default: 2000,
      },
    };
  }

  show() {
    this.alertTarget.classList.remove('opacity-0', 'translate-x-full');
    this.alertTarget.classList.add('opacity-100');

    setTimeout(() => {
      this.fadeOut();
    }, this.durationValue);
  }

  fadeOut() {
    this.alertTarget.classList.add('opacity-0', 'translate-x-full');
    this.alertTarget.classList.remove('opacity-100');
  }
}
